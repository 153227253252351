<template>
  <div class="w-100">
    <ValidateForm @validated="submit" :showFieldObl="false">
      <v-row justify="center">
        <v-col cols="12" xl="10" class="gestion-du-club__form">
          <div>
            <InputText
              v-model="nomStructure"
              rules="min:2"
              label="Nom de la structure"
            ></InputText>
            <InputText
              v-model="complementNomStructure"
              rules="min:2|max:100"
              label="Complément de nom"
            ></InputText>
          </div>
          <div>
            <InputText
              rules="min:2|max:20"
              v-model="codeStructure"
              label="Code structure"
            ></InputText>
          </div>
          <div>
            <selectInput
              :items="structures"
              v-model="typeStructure"
              item_text="NomTypeStructure"
              item_value="id"
              label="Type de structure"
            ></selectInput>
          </div>
          <div>
            <selectInput
              :items="regions"
              v-model="regionSelected"
              item_text="NomRegion"
              item_value="id"
              label="Région"
              @onChange="regionChanged"
            ></selectInput>
            <selectInput
              :items="departements"
              v-model="departementSelected"
              item_text="DepName"
              item_value="id"
              label="Département"
            ></selectInput>
          </div>

          <v-row
            class="d-flex align-items-center"
            style="width: 100%; height: 60px"
          >
            <v-col cols="3">
              <label for="checkbox" style="font-size: 12.8px; margin: 0"
                >Structure affiliée à la FFCAM</label
              >
            </v-col>
            <v-col cols="9" class="d-flex">
              <v-checkbox
                style="width: 15%"
                v-model="EST_FFCAM"
                class="selection_vente"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            class="d-flex align-items-center"
            style="width: 100%; height: 60px"
          >
            <v-col cols="3">
              <label for="checkbox" style="font-size: 12.8px; margin: 0"
                >Structure partenaire Block'Out</label
              >
            </v-col>
            <v-col cols="9" class="d-flex">
              <v-checkbox
                style="width: 15%"
                v-model="EST_Blockout"
                class="selection_vente"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            class="d-flex align-items-center"
            style="width: 100%; height: 60px"
          >
            <v-col cols="3">
              <label for="checkbox" style="font-size: 12.8px; margin: 0"
                >Structure partenaire Climb'Up</label
              >
            </v-col>
            <v-col cols="9" class="d-flex">
              <v-checkbox
                style="width: 15%"
                v-model="EST_ClimbUp"
                class="selection_vente"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            class="d-flex align-items-center"
            style="width: 100%; height: 60px"
          >
            <v-col cols="3">
              <label for="checkbox" style="font-size: 12.8px; margin: 0"
                >Section d'association</label
              >
            </v-col>
            <v-col cols="9" class="d-flex">
              <v-checkbox
                style="width: 15%"
                v-model="EST_SectionAssociation"
                class="selection_vente"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row
            class="d-flex align-items-center"
            style="width: 100%; height: 60px"
          >
            <v-col cols="3">
              <label for="checkbox" style="font-size: 12.8px; margin: 0"
                >Blocage du compte</label
              >
            </v-col>
            <v-col cols="9" class="d-flex">
              <v-checkbox
                style="width: 15%"
                v-model="EST_Blocked"
                class="selection_vente"
              ></v-checkbox>
            </v-col>
          </v-row>

          <div style="margin-top: 60px">
            <v-btn type="submit" class="btn btn-primary" :disabled="canSubmit"
              >Mettre à jour</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ValidateForm from '@/components/utils/form.vue';

import InputText from '@/components/utils/input.vue';
import selectInput from '@/components/utils/select.vue';
// import InputCheckbox from "@/components/utils/checkbox.vue";
import {
  success_notification,
  error_notification,
} from '@/plugins/notifications';
import LoginVue from '../../../views/Auth/Login.vue';

export default {
  name: 'PresentationDuClub',

  components: {
    InputText,
    ValidateForm,
    selectInput,
  },

  data: () => ({
    departements: [],
    structures: [],
    nomStructure: '',
    complementNomStructure: '',
    codeStructure: '',
    typeStructure: '',
    departementSelected: '',
    regions: [],
    regionSelected: '',
    EST_FFCAM: false,
    EST_Blockout: false,
    EST_ClimbUp: false,
    EST_Blocked: false,
    EST_SectionAssociation: false,
    departementsTemp: [],
    getStructure: null,
    structureID: null,
  }),

  async created() {
    await this.setData();
  },

  computed: {
    ...mapGetters('structure', ['currentStructureId']),

    canSubmit() {
      if (
        !this.nomStructure &&
        !this.typeStructure &&
        !this.departementSelected &&
        !this.regionSelected &&
        !this.EST_FFCAM &&
        !this.EST_Blockout &&
        !this.EST_ClimbUp &&
        !this.EST_Blocked &&
        !this.EST_SectionAssociation
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions('basics', [
      'getListStructureType',
      'getDepartementsList',
      'getRegionList',
    ]),
    ...mapActions('structure', [
      'getStructureByID',
      'updateStructureAdministration',
    ]),

    departementChanged(dep) {
      console.log('dep', dep);
    },
    regionChanged(reg) {
      const currentRegion = this.regions.find((region) => region.id == reg);
      if (currentRegion.ADR_Departement.length === 0) {
        this.departementSelected = null;
      }
      this.departements = currentRegion?.ADR_Departement.map((departement) => {
        return {
          ...departement,
          DepName: `${departement.CodeDepartement} - ${departement.NomDepartement}`,
        };
      });
    },

    async setData() {
      this.structureID = parseInt(this.$route.params.id);
      if (!this.structureID) this.structureID = this.currentStructureId;

      this.getStructure = await this.getStructureByID(this.structureID);
      let allStructures = await this.getListStructureType();
      this.structures = allStructures.filter((s) => {
        return (
          s.SlugTypeStructure != 'licencie_hors_club' &&
          s.SlugTypeStructure != 'comite_sportif' &&
          s.SlugTypeStructure != 'club_caf' &&
          s.SlugTypeStructure != 'club_section_association'
        );
      });
      this.regions = await this.getRegionList();
      this.departementsTemp = await this.getDepartementsList();

      if (this.getStructure?.ADR_Region?.id) {
        const currentRegion = this.regions.find(
          (region) => region.id == this.getStructure?.ADR_Region?.id
        );

        this.departements = currentRegion?.ADR_Departement.map(
          (departement) => {
            return {
              ...departement,
              DepName: `${departement.CodeDepartement} - ${departement.NomDepartement}`,
            };
          }
        );
      } else {
        this.departements = this.departementsTemp?.map((departement) => {
          return {
            ...departement,
            DepName: `${departement.CodeDepartement} - ${departement.NomDepartement}`,
          };
        });
      }
      this.nomStructure = this.getStructure.NomStructure;

      this.complementNomStructure = this.getStructure.NomStructureComplement;
      this.codeStructure = this.getStructure.ID_CodeFederal;
      this.typeStructure = this.getStructure.ID_TypeStructure;
      this.departementSelected = this.departements.find(
        (depart) => depart.id == this.getStructure?.ADR_Departement?.id
      );
      this.regionSelected = this.regions.find(
        (region) => region.id == this.getStructure?.ADR_Region?.id
      );

      this.EST_Blockout = this.getStructure.EST_BlockOut;
      this.EST_Blocked = this.getStructure.EST_CompteBloque;
      this.EST_FFCAM = this.getStructure.EST_ClubCAF;
      this.EST_ClimbUp = this.getStructure.EST_ClimbUp;
      this.EST_SectionAssociation = this.getStructure.EST_SectionAssociation;
    },

    async submit() {
      // const payload = this.payloadData();
      const oldNomStructure = this.getStructure.NomStructure;
      let selectedRegion = '';
      let selectedDepartement = '';

      if (typeof this.regionSelected === 'object') {
        selectedRegion = this.regionSelected?.id;
      } else {
        selectedRegion = this.regionSelected;
      }

      if (typeof this.departementSelected === 'object') {
        selectedDepartement = this.departementSelected?.id
          ? this.departementSelected.id
          : null;
      } else {
        selectedDepartement = this.departementSelected
          ? this.departementSelected
          : null;
      }
      const payload = {
        id: this.structureID,
        STR_data: {
          NomStructure: this.nomStructure,
          NomStructureComplement: this.complementNomStructure,
          ID_CodeFederal: this.codeStructure,
          ID_TypeStructure: this.typeStructure,
          ID_Departement: selectedDepartement,
          ID_Region: selectedRegion,
          EST_ClubCAF: this.EST_FFCAM,
          EST_ClimbUp: this.EST_ClimbUp,
          EST_BlockOut: this.EST_Blockout,
          EST_CompteBloque: this.EST_Blocked,
          EST_SectionAssociation: this.EST_SectionAssociation,
        },
      };
      console.log('STR_data', payload);

      try {
        await this.updateStructureAdministration(payload);
        if (oldNomStructure !== this.nomStructure) {
          this.$router.go();
        }
        success_notification('Modification(s) enregistrée(s)');
      } catch (error) {
        console.log('error update admin', error);
        error_notification('Oups, une erreur est survenue...');
      }
    },
  },
};
</script>

<style>
.activities_selected .v-input__slot {
  overflow: hidden;
}
</style>
