<template>
  <b-row class="m-0 login__container">
    <b-col cols="12" md="3" class="login-img"></b-col>
    <b-col cols="12" md="9">
      <b-row class="login-form" align-h="center" align-v="center">
        <b-col cols="12" md="4" class="login-logo"></b-col>

        <b-col cols="12" md="8">
          <ValidationObserver ref="signInForm">
            <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>

            <b-form @submit.prevent="onSubmit()">
              <InputText
                v-model="form.username"
                rules="required"
                name="identifiant"
                placeholder="Nom d'utilisateur ou ID"
                custom_class="__gray"
              ></InputText>
              <InputText
                v-model="form.password"
                rules="required"
                type="password"
                name="mot de passe"
                placeholder="Mot de passe"
                custom_class="__gray"
                ref="signInPassword"
              ></InputText>
              <InputCheckbox
                v-model="form.remember_me"
                placeholder="Mot de passe"
              ></InputCheckbox>

              <b-button
                type="submit"
                variant="primary"
                class="__full mt-5 text-uppercase font-weight-bold"
                >Se connecter</b-button
              >
            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from "vuex";

import InputText from "@/components/utils/input.vue";
import InputCheckbox from "@/components/utils/checkbox.vue";

export default {
  name: "Login",

  components: { InputText, InputCheckbox },

  data() {
    return {
      form: {
        username: "",
        password: "",
        remember_me: false,
      },
      error: false,
    };
  },

  methods: {
    ...mapActions("auth", ["signIn"]),

    async onSubmit() {
      await this.$refs.signInForm.validate().then((validate) => {
        if (!validate) {
          this.error = "Merci de corriger les champs";
        } else {
          this.error = false;
          this.submit();
        }
      });
    },

    async submit() {
      await this.signIn(this.form)
        .then((data) => {
          if (data && data.errors) {
            this.error = data.errors;
            this.$refs.signInPassword.currentValue = "";
          } else {
            //this.$router.push({ name : 'Home'})
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style>
.login__container {
  height: 100vh;
  display: block !important;
}

.login-img {
  height: 30%;
  background: url("~@/assets/images/home_bg.jpeg") no-repeat;
  background-size: cover;
}

.login-form {
  height: 100%;
}

.login-logo {
  background: url("~@/assets/images/logo_png.png") no-repeat;
  background-size: auto 80%;
  background-position: top center;
  width: 100%;
  height: 300px;
}

@media (min-width: 768px) {
  .login__container {
    display: flex !important;
  }
  .login-img {
    height: 100%;
  }

  .login-form {
    max-width: 800px;
    margin: 0 auto;
  }
}
</style>
