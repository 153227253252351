<template>
  <div class="w-100">
    <v-row justify="center">
      <v-col cols="12" xl="10" class="gestion-du-club__form">
        <ValidateForm
          v-if="!loading"
          @validated="submit"
          :loading="loading"
          :showFieldObl="mandatory"
        >
          <Textarea
            v-model="structure.INFO_Presentation"
            rules="required"
            :label="'Texte de présentation ' + labelPres"
            :disabled="!hasCodeFederal"
          ></Textarea>

          <div v-if="!affiliations">
            <v-row>
              <v-col cols="10">
                <v-alert border="left" color="grey lighten-2">
                  <p class="mb-0">Information</p>
                  <p class="mb-0 font-weight-bold">Cette structure n'a pas encore commencé son affiliation vous ne pouvez pas la modifier pour le moment</p>
                </v-alert>
              </v-col>
            </v-row>
          </div>

          <div v-if="(isCT || isLigue) && (!isAdmin || !isClub) && affiliations">
            <RadioGroup
              name="CLUB_EST_AcceuillantHandicape"
              v-model="affiliations.CLUB_EST_AcceuillantHandicape"
              :checked="affiliations.CLUB_EST_AcceuillantHandicape"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Accueil de personnes en situation de handicap"
            ></RadioGroup>

            <SelectInput
              v-if="affiliations.CLUB_EST_AcceuillantHandicape"
              label="Type de handicap"
              rules="required"
              :items="listDisabilities"
              v-model="structureDisabilities"
              item_text="name"
              item_value="id"
              multiple
            ></SelectInput>

            <RadioGroup
              name="CLUB_EST_HandiGuide"
              v-model="affiliations.CLUB_EST_HandiGuide"
              :checked="affiliations.CLUB_EST_HandiGuide"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Êtes-vous inscrits au Handi Guide du Ministère des Sports ?"
            ></RadioGroup>
          </div>

          <div v-if="(isClub || isAdmin) && affiliations">
            <InputSelect
              class="force-w-100 activities_selected"
              v-model="activites"
              :items="allActivities"
              :multiple="true"
              item_value="ID_Activite"
              item_text="LIST_ActiviteType.NomActivite"
              label="Activités proposées par la structure"
              placeholder="Sélectionnez une ou plusieurs activités"
              :disabled="!hasCodeFederal"
            ></InputSelect>

            <v-row class="justify-content-end" v-if="activites.length > 0">
              <v-col cols="12" md="9">
                <v-card outlined>
                  <v-list dense>
                    <v-list-item-group multiple>
                      <v-list-item
                        v-for="(item, index) in activites"
                        :key="index"
                        @click="TogglePrimaryActivity(item.ID_Activite)"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.LIST_ActiviteType.NomActivite"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-icon
                            :color="
                              item.EST_ActivitePrincipale
                                ? 'yellow'
                                : 'grey lighten-1'
                            "
                          >
                            mdi-star
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-card-title class="justify-content-end">
                    <v-icon size="medium" color="yellow"> mdi-star </v-icon>
                    <span class="caption ml-2">Activité principale</span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="12">
                <inputText
                  v-model="structure.JUR_Siret"
                  label="N° SIRET"
                  rules="siret"
                />
                <inputText
                  v-model="structure.JUR_AgrementJeunesseSports"
                  label="Agrément Jeunesse et sports"
                />
              </v-col>
            </v-row>

            <RadioGroup
              name="CLUB_EST_AcceuillantHandicape"
              v-model="affiliations.CLUB_EST_AcceuillantHandicape"
              :checked="affiliations.CLUB_EST_AcceuillantHandicape"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Accueil de personnes en situation de handicap"
            ></RadioGroup>

            <SelectInput
              v-if="affiliations.CLUB_EST_AcceuillantHandicape"
              label="Type de handicap"
              rules="required"
              :items="listDisabilities"
              v-model="structureDisabilities"
              item_text="name"
              item_value="id"
              multiple
            ></SelectInput>

            <RadioGroup
              name="CLUB_EST_HandiGuide"
              v-model="affiliations.CLUB_EST_HandiGuide"
              :checked="affiliations.CLUB_EST_HandiGuide"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Êtes-vous inscrits au Handi Guide du Ministère des Sports ?"
            ></RadioGroup>

            <RadioGroup
              name="CLUB_EST_AcceuillantMineur"
              v-model="affiliations.CLUB_EST_AcceuillantMineur"
              :checked="affiliations.CLUB_EST_AcceuillantMineur"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Mineurs acceptés"
            ></RadioGroup>

            <InputText
              v-model="affiliations.CLUB_AcceuillantMineurAge"
              label="Âge minimum"
              type="number"
              :minValue="1"
            ></InputText>

            <RadioGroup
              name="CLUB_EST_AcceptePassSportCulture"
              v-model="affiliations.CLUB_EST_AcceptePassSportCulture"
              :checked="affiliations.CLUB_EST_AcceptePassSportCulture"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Propose les passeports escalade"
            ></RadioGroup>

            <RadioGroup
              name="CLUB_EST_PretMateriel"
              v-model="affiliations.CLUB_EST_PretMateriel"
              :checked="affiliations.CLUB_EST_PretMateriel"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Prêt de matériel (EPI)"
            ></RadioGroup>

            <RadioGroup
              name="CLUB_EST_ConventionAllocationFamilliale"
              v-model="affiliations.CLUB_EST_ConventionAllocationFamilliale"
              :checked="affiliations.CLUB_EST_ConventionAllocationFamilliale"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Convention avec les Caisses d'Allocation Familialles"
            ></RadioGroup>

            <RadioGroup
              name="CLUB_EST_AccepteChequesVacances"
              v-model="affiliations.CLUB_EST_AccepteChequesVacances"
              :checked="affiliations.CLUB_EST_AccepteChequesVacances"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Accepte les chèques vacances"
            ></RadioGroup>

            <RadioGroup
              name="CLUB_EST_AccepteCouponSport"
              v-model="affiliations.CLUB_EST_AccepteCouponSport"
              :checked="affiliations.CLUB_EST_AccepteCouponSport"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Accepte les coupons sport"
            ></RadioGroup>

            <RadioGroup
              name="CLUB_EST_PreparePasseport"
              v-model="affiliations.CLUB_EST_PreparePasseport"
              :checked="affiliations.CLUB_EST_PreparePasseport"
              :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
              label="Accepte les pass'sport culture"
            ></RadioGroup>
          </div>

          <v-btn
            v-if="canSubmit && hasCodeFederal"
            type="submit"
            :loading="loading"
            :disabled="!affiliations"
            class="btn btn-primary"
            >Mettre à jour</v-btn
          >

          <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn type="submit" :disabled="true" class="btn btn-primary"
                  >Mettre à jour</v-btn
                >
              </span>
            </template>
            <span
              >Vous n'avez pas les droits nécessaire pour accéder à ce module.
              Merci de contacter un administrateur.</span
            >
          </v-tooltip>
        </ValidateForm>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidateForm from "@/components/utils/form.vue";
import Textarea from "@/components/utils/textarea.vue";
import InputSelect from "@/components/utils/selectWithInput.vue";
import RadioGroup from "@/components/utils/radioGroup.vue";
import InputText from "@/components/utils/input.vue";
import SelectInput from "@/components/utils/select.vue";

export default {
  name: "PresentationDuClub",

  components: {
    InputText,
    Textarea,
    InputSelect,
    RadioGroup,
    ValidateForm,
    SelectInput,
  },

  data: () => ({
    loading: true,
    types_activities: [],
    structure: {},
    activites: [],
    affiliations: [],
    mandatory: false,
    JUR_AgrementJeunesseSports: null,
    JUR_Siret: null,
    structureID: null,
    listDisabilities: [],
    structureDisabilities: [],
  }),

  async beforeRouteLeave(to, from, next) {
    if (!this.dataEquals()) {
      const answer = window.confirm(
        "Voulez-vous vraiment partir? Vous avez des changements non enregistrés!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  async created() {
    this.types_activities = await this.getListTypeActivite();
    this.listDisabilities = await this.getListDisabilities();

    await this.setData();
    this.loading = false;
    return;
  },

  methods: {
    ...mapActions("structure", [
      "getStructureActivite",
      "getStructureAffiliation",
      "updatePresentationStructure",
      "updateInfoPresentationStructure",
      "getStructureByID",
      "getStructureActiviteFromStrID",
      "getStructureDisabilities",
    ]),

    ...mapActions("basics", ["getListTypeActivite", "getListDisabilities"]),

    dataEquals() {
      // if (
      //   JSON.stringify(this.structure) !==
      //     JSON.stringify(this.currentStructure.STR_Structure) ||
      //   JSON.stringify(this.activites) !==
      //     JSON.stringify(this.currentStructure.ACTI_ActivitePratiquee) ||
      //   JSON.stringify(this.affiliations) !==
      //     JSON.stringify(this.currentStructure.STR_Affiliation)
      // ) {
      //   return false;
      // }

      return true;
    },

    async setData() {
      console.log("this.currentStructure", this.currentStructure);
      console.log("this.getRole", this.getRole);
      this.structureID = parseInt(this.$route.params.id);
      if (this.isAdmin || this.structureID) {
        // this.structure = { ...this.currentStructure.STR_Structure };
        this.structure = await this.getStructureByID(this.structureID);
        // const ato = await this.getStructureActiviteFromStrID(this.structureID);
        console.log("this.structure", this.structure);

        // console.log('🚀 ~ setData ~ this.currentStructure', this.structure);
        this.activites = [...this.structure.ACTI_ActivitePratiquees];
        this.affiliations = await this.getStructureAffiliation({
          ID_Structure: this.structureID,
          ID_Saison: this.selectedSaison.id,
        });

        this.structureDisabilities = await this.getStructureDisabilities(this.structureID);
        this.structureDisabilities = this.structureDisabilities.map(
          (disability) => disability.id_disability
        );
      } else {
        // await this.getStructureActivite();
        console.log("else this.currentStructure", this.currentStructure);

        console.log("else this.getRole", this.getRole);

        // this.structureID = parseInt(this.$route.params.id);
        // if (!this.structureID) this.structureID = this.currentStructureId;

        this.structure = await this.getStructureByID(this.currentStructureId);
        this.activites = [...this.structure.ACTI_ActivitePratiquees];

        // this.structure = { ...this.currentStructure.STR_Structure };

        // this.activites = [...this.currentStructure.ACTI_ActivitePratiquee];
        this.affiliations = await this.getStructureAffiliation({
          ID_Structure: this.currentStructureId,
          ID_Saison: this.selectedSaison.id,
        });

        this.structureDisabilities = await this.getStructureDisabilities(this.currentStructureId);
        this.structureDisabilities = this.structureDisabilities.map(
          (disability) => disability.id_disability
        );
      }

      // console.log(
      //   'atooo',
      //   JSON.stringify({
      //     structure: this.structure,
      //     activites: this.activites,
      //     affiliations: this.affiliations,
      //   })
      // );
      return;
    },

    async submit() {
      this.loading = true;

      // Output activities graphql object
      const payload = {
        structure: this.structure,
        activites: this.activites,
        affiliations: this.affiliations,
        disabilities: this.affiliations.CLUB_EST_AcceuillantHandicape ? this.structureDisabilities.map((handicapId) => { return { id_structure: this.structure.id, id_disability: handicapId }; }) : [],
      };

      try {
        // if (!this.isClub) {
        //   await this.updateInfoPresentationStructure(payload);
        // } else {
        await this.updatePresentationStructure(payload);
        // }
        this.loading = false;
        this.setData();
        this.$toast.success("Information modifiée", {
          timeout: 5000,
        });
      } catch (e) {
        this.loading = false;
        this.$toast.error("Oups, une erreur est survenue...", {
          timeout: 5000,
        });
      }
    },

    TogglePrimaryActivity(itemId) {
      let selectedActivite = this.activites.find(
        (obj) => obj.ID_Activite == itemId
      );
      this.activites.forEach((obj) => (obj.EST_ActivitePrincipale = false));
      selectedActivite.EST_ActivitePrincipale = true;
    },
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["selectedSaison"]),
    ...mapGetters("user", ["can", "getRole"]),

    canSubmit() {
      return this.can("information_de_la_structure__presentation");
    },

    allActivities() {
      return this.types_activities.map((obj) => ({
        EST_ActivitePrincipale: false,
        ID_Activite: obj.id,
        LIST_ActiviteType: {
          ...obj,
        },
      }));
    },
    isAdmin() {
      console.log("this.getRole", this.getRole);
      let role = this.getRole?.SlugRole;
      if (
        role === "super_administrateur" ||
        role === "administrateur_vie_territoriale"
      )
        return true;
      return false;
    },
    isClub() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club;
    },
    isCT() {
      return (
        this.currentStructure.STR_Structure.LIST_StructureType
          .SlugTypeStructure == "comite_territorial"
      );
    },
    isLigue() {
      return (
        this.currentStructure.STR_Structure.LIST_StructureType
          .SlugTypeStructure == "ligue"
      );
    },

    labelPres() {
      let type = "";
      if (this.structureID)
        type = this.structure.LIST_StructureType.SlugTypeStructure;
      else
        type =
          this.currentStructure.STR_Structure.LIST_StructureType
            .SlugTypeStructure;
      if (type == "ligue") {
        return "de la ligue";
      } else if (type == "comite_territorial") {
        return "du CT";
      } else {
        return "du club";
      }
    },
  },
};
</script>

<style>
.activities_selected .v-input__slot {
  overflow: hidden;
}
</style>
